import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Box } from '@chakra-ui/react'
import ProjectCardMotion from '../components/cards/ProjectCardMotion'
import PcTest from '../components/cards/PcTest'



const Tester = () => {
    return (
        <div>
      <Box w="100vw">
         <PcTest>
             <StaticImage src='../images/wilson/img2.jpg' layout="constrained"/>
         </PcTest>
         </Box>
        </div>
    )
}

export default Tester
