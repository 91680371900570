

import React, { useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { StaticImage } from 'gatsby-plugin-image';
import './ProjectCardMotion.css'

const preventClick = (e) => e.preventDefault()
const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / 50,
  (x - rect.left - rect.width / 2) / 50,
  1.1
];
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export default function PcTest({w='1920', h='1080', href='#', title, subtitle, src,children}) {
  const ref = useRef(null);
  const [xys, set] = useState([0, 0, 1]);
  const config = {
    mass: 1,
    tension: 170,
    friction: 26,
    clamp: true,
    precision: 0.01,
    velocity: 0,
    easing: (t) => t
  };
  const props = useSpring({ xys, config });

  return (
  
    <div onContextMenu={preventClick} className="ccard-main portrait mx-auto" ref={ref}>
      <animated.div
        className="ccard portrait"
        style={{ color: 'white', transform: props.xys.to(trans) }}
        onMouseLeave={() => set([0, 0, 1])}
        onMouseMove={(e) => {
          const rect = ref.current.getBoundingClientRect();
          set(calc(e.clientX, e.clientY, rect));
        }}
      ><img src='../../images/wilson/img2.jpg'/></animated.div>
      
    </div>
    
  );
}
